import { useState, useEffect, useCallback, useRef } from 'react';
import { Box, Avatar, List, ListItem, ListItemText, Typography, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ReactMarkdown from 'react-markdown';
import PersonIcon from '@mui/icons-material/Person';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import SettingsIcon from '@mui/icons-material/Settings';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import BuildIcon from '@mui/icons-material/Build';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import MessageInput from './MessageInput';
import axios from 'axios';
import { Message } from '../types'; // Import the Message type

interface ChatProps {
  chat: any[];
  messages: Message[];
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  newMessageAdded: boolean;
  setNewMessageAdded: React.Dispatch<React.SetStateAction<boolean>>;
  selectedChat: any;
  accessToken: string;
  apiLink: string;
  ws: WebSocket;
  wsReady: boolean;
  onSelectMessage: (message: Message) => void;
}

const icons = {
  HUMAN: <PersonIcon />,
  AI: <SmartToyIcon />,
  SYSTEM: <SettingsIcon />,
  FUNCTION_CALL: <CallSplitIcon />,
  TOOL_CALL: <BuildIcon />,
  FUNCTION_RESULT: <CheckCircleIcon />,
  TOOL_RESULT: <DoneAllIcon />,
};

const backgroundColors = {
  HUMAN: '#D0E8FF',
  AI: '#DFF7DF',
  SYSTEM: '#EADCF8',
  FUNCTION_CALL: '#FFE5CC',
  TOOL_CALL: '#FFD6D6',
  FUNCTION_RESULT: '#DFF4FF',
  TOOL_RESULT: '#FFF4CC',
};

const hasLangsmithId = (messages: Message[]): string | false => {
  return messages.slice().reverse().find((message) => message.type === 'HUMAN')?.langsmith_id || false;
};

const Chat: React.FC<ChatProps> = ({ chat, messages, setMessages, newMessageAdded, setNewMessageAdded, selectedChat, accessToken, apiLink, ws, wsReady, onSelectMessage }) => {
  const [requestForMessagesHaseBeenSent, setRequestForMessagesHaseBeenSent] = useState(false);
  const [attempts, setAttempts] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [messagesLoaded, setMessagesLoaded] = useState(false);
  const maxAttempts = 3;
  const prevSelectedChatRef = useRef<any>();
  const listRef = useRef<HTMLDivElement | null>(null);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    console.log('messages.count:', messages.length);
  }, [messages]);

  const fetchChatHistory = useCallback(async (chatId: string, botId: string, page = 1, isLongPolling = false) => {
    const pageSize = isLongPolling ? messages.length : 50;
    const maxPageSize = 50; // Assuming the API limit is 100 messages per request

    const fetchMessages = async (pageSize: number, page: number) => {
      try {
        const response = await axios.get(`${apiLink}/chat/messages/${botId}/WEBSOCKET_${chatId}?page_size=${pageSize}&page=${page}`, {
          headers: { Authorization: `Bearer ${accessToken}` },
          params: { long_polling: isLongPolling }
        });
        console.log('Fetched messages:', response.data.messages.length);
        return Array.isArray(response.data.messages) ? response.data.messages.reverse() : [];
      } catch (error) {
        console.error('Error fetching chat history:', error);
        return [];
      }
    };

    const fetchAllMessages = async () => {
      let allMessages: Message[] = [];
      let currentPage = page;
      let remainingMessages = pageSize;

      while (remainingMessages > 0) {
        const currentPageSize = Math.min(pageSize, maxPageSize);
        const messagesChunk = await fetchMessages(currentPageSize, currentPage);
        allMessages = [...allMessages, ...messagesChunk];
        remainingMessages -= messagesChunk.length;
        currentPage += 1;

        if (messagesChunk.length < currentPageSize) {
          break; // No more messages to fetch
        }
      }

      return allMessages;
    };

    const reversedMessages = await fetchAllMessages();

    setMessages((prevMessages) => {
      if (isLongPolling) {
        return reversedMessages;
      }

      const updatedMessages = [...reversedMessages, ...prevMessages];

      const lastUserMessageIndex = updatedMessages.findIndex((msg) => msg.type === 'HUMAN' && !msg.langsmith_id);
      if (lastUserMessageIndex !== -1) {
        const fetchedMessage = reversedMessages.find((msg) => msg.langsmith_id);
        if (fetchedMessage) {
          updatedMessages[lastUserMessageIndex].langsmith_id = fetchedMessage.langsmith_id;
        }
      }

      const uniqueMessages = updatedMessages.filter((msg, index, self) => {
        return (
          index ===
          self.findIndex(
            (m) =>
              m.id === msg.id && m.created_at === msg.created_at
          )
        );
      });

      console.log('Unique messages count:', uniqueMessages.length);
      return uniqueMessages;
    });

    if (reversedMessages.length < pageSize) {
      setHasMoreMessages(false);
    }
  }, [apiLink, accessToken, setMessages, messages.length, setHasMoreMessages]);

  const startPolling = useCallback(() => {
    intervalRef.current = setInterval(() => {
      if (selectedChat !== null && messages.length > 0) {
        if (hasLangsmithId(messages) || attempts >= maxAttempts) {
          clearInterval(intervalRef.current!);
          intervalRef.current = null;
          return;
        }

        fetchChatHistory(selectedChat.chat_id, selectedChat.bot_id, 1, true);
        setAttempts((prevAttempts) => prevAttempts + 1);
      }
    }, 15000);
  }, [selectedChat, messages, attempts, maxAttempts, fetchChatHistory]);

  useEffect(() => {
    if (newMessageAdded && listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
      setNewMessageAdded(false);
    }
    setAttempts(0);
  }, [newMessageAdded, setNewMessageAdded]);

  useEffect(() => {
    if (messages.length > 0 && listRef.current && !messagesLoaded) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
      setMessagesLoaded(true);
    }
  }, [messages, messagesLoaded]);

  useEffect(() => {
    setRequestForMessagesHaseBeenSent(false);
    setMessages([]);
    setCurrentPage(1);
    setHasMoreMessages(true);
    setMessagesLoaded(false);
  }, [selectedChat, setMessages]);

  useEffect(() => {
    console.log('selectedChat:', selectedChat);
    if (selectedChat !== null && messages.length === 0 && !requestForMessagesHaseBeenSent) {
      const selectedChatData = chat.find((c) => c.chat_id === selectedChat);
      setMessages(Array.isArray(selectedChatData?.messages) ? selectedChatData.messages : []);
      fetchChatHistory(selectedChat.chat_id, selectedChat.bot_id, currentPage);
      setRequestForMessagesHaseBeenSent(true);
    }

    const delayBeforePolling = 15000;
    const timeoutId = setTimeout(() => {
      startPolling();
    }, delayBeforePolling);

    return () => {
      clearTimeout(timeoutId);
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [
    selectedChat,
    chat,
    apiLink,
    accessToken,
    messages,
    setMessages,
    setRequestForMessagesHaseBeenSent,
    requestForMessagesHaseBeenSent,
    attempts,
    fetchChatHistory,
    startPolling,
    currentPage,
  ]);

  const subscribeToWebSocket = useCallback(() => {
    if (selectedChat && ws && ws.readyState === WebSocket.OPEN) {
      const subscribeMessage = {
        type: 'subscribe',
        chat_id: selectedChat.chat_id,
        bot_id: selectedChat.bot_id,
      };
      ws.send(JSON.stringify(subscribeMessage));
      prevSelectedChatRef.current = {
        chat_id: selectedChat.chat_id,
        bot_id: selectedChat.bot_id,
      };
    }
  }, [selectedChat, ws]);

  const unsubscribeFromWebSocket = useCallback(() => {
    if (prevSelectedChatRef.current) {
      const { chat_id, bot_id } = prevSelectedChatRef.current;
      const unsubscribeMessage = {
        type: 'unsubscribe',
        chat_id,
        bot_id,
      };
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify(unsubscribeMessage));
      }
    }
  }, [ws]);

  const handleWebSocketConnection = useCallback(() => {
    if (ws && ws.readyState !== WebSocket.OPEN) {
      const interval = setInterval(() => {
        if (ws.readyState === WebSocket.OPEN) {
          clearInterval(interval);
          subscribeToWebSocket();
        }
      }, 1000);
      return () => clearInterval(interval);
    } else {
      subscribeToWebSocket();
    }
  }, [ws, subscribeToWebSocket]);

  useEffect(() => {
    const manageWebSocketSubscription = () => {
      unsubscribeFromWebSocket();
      handleWebSocketConnection();
    };

    manageWebSocketSubscription();
    // Update the ref with the current selectedChat
    prevSelectedChatRef.current = {
      chat_id: selectedChat.chat_id,
      bot_id: selectedChat.bot_id,
    };

    return () => {
      unsubscribeFromWebSocket();
    };
  }, [selectedChat, ws, wsReady, setMessages, subscribeToWebSocket, unsubscribeFromWebSocket, handleWebSocketConnection]);

  const debounce = (func: Function, wait: number) => {
    let timeout: NodeJS.Timeout;
    return (...args: any[]) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  };

  useEffect(() => {
    const handleScroll = debounce(() => {
      if (listRef.current && listRef.current.scrollTop === 0 && hasMoreMessages) {
        const currentScrollTop = listRef.current.scrollTop;
        const currentScrollHeight = listRef.current.scrollHeight;

        const nextPage = currentPage + 1;
        fetchChatHistory(selectedChat.chat_id, selectedChat.bot_id, nextPage).then(() => {
          setTimeout(() => {
            if (listRef.current) {
              const newScrollHeight = listRef.current.scrollHeight;
              listRef.current.scrollTop = newScrollHeight - currentScrollHeight + currentScrollTop;
            }
          }, 100);
        });

        setCurrentPage(nextPage);
      }
    }, 200);

    const listElement = listRef.current;
    if (listElement) {
      listElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (listElement) {
        listElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [currentPage, hasMoreMessages, selectedChat, fetchChatHistory]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', padding: '20px', flex: 1, maxWidth: '1624px', marginInline: 'auto' }}>
      <Box style={{ marginBottom: '20px', flex: 1, padding: '10px', border: '1px solid #c4c4c4', overflow: 'auto' }} ref={listRef}>
        <List style={{ display: 'flex', flexDirection: 'column', gap: '10px' }} id="chat-list">
          {Array.isArray(messages) &&
            messages
              .sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime())
              .map((message, index) => (
                <ListItem
                  key={index}
                  style={{
                    backgroundColor: backgroundColors[message.type as keyof typeof backgroundColors],
                    borderRadius: '8px',
                    marginBottom: '10px',
                    padding: '10px',
                    width: '50%',
                    alignSelf: message.type === 'HUMAN' ? 'flex-start' : 'flex-end',
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Avatar style={{ marginRight: '10px' }}>{icons[message.type as keyof typeof icons]}</Avatar>
                  <ListItemText
                    primary={
                      message.type === 'text' ? (
                        message.content
                      ) : (
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                          <div style={{ fontFamily: 'Arial', whiteSpace: 'pre-wrap', display: 'flex', flexDirection: 'column' }}>
                            <ReactMarkdown>{message.content}</ReactMarkdown>
                          </div>
                          {message.content && message.files.length > 0 && <br />}
                          {Array.isArray(message.files) && message.files.length > 0 && (
                            <Typography variant="caption" style={{ color: 'gray' }}>
                              {message.files.length > 1 ? 'Files: ' : 'File: '}
                              {message.files.map((file) => file.file_name).join(', ')}
                            </Typography>
                          )}
                        </div>
                      )
                    }
                  />
                  {message.langsmith_id && (
                    <IconButton
                      style={{ position: 'absolute', top: '10px', right: '10px' }}
                      onClick={() => onSelectMessage(message)}
                    >
                      <InfoIcon />
                    </IconButton>
                  )}
                </ListItem>
              ))}
        </List>
      </Box>
      <MessageInput
        selectedChat={selectedChat}
        ws={ws}
        setMessages={setMessages}
        setNewMessageAdded={setNewMessageAdded}
      />
    </div>
  );
};

export default Chat;
