import React, { useState, useEffect, useCallback, useRef } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton } from '@mui/material';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import GraphExplorer from './Components/GraphExplorer';
import { v4 as uuidv4 } from 'uuid';
import Sidebar from './Components/Sidebar';
import Chat from './Components/Chat';
import Login from './Components/Login';
import MessageInfo from './Components/MessageInfo';
import Cookies from 'js-cookie';
import axios from 'axios';

import { Message } from './types'; // Import the Message type

import './App.css';

const App: React.FC = () => {
  const [chats] = useState<any[]>([]);
  const [selectedChat, setSelectedChat] = useState<any | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [wsReady, setWsReady] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState<Message | null>(null);
  const [commitInfo, setCommitInfo] = useState('');
  const [messageInfoWidth, setMessageInfoWidth] = useState('500px');
  const [apiLink, setApiLink] = useState('');
  const [websocketApiLink, setWebsocketApiLink] = useState('');
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessageAdded, setNewMessageAdded] = useState(false);

  const accessToken = useRef<string>('');
  const ws = useRef<WebSocket | null>(null);
  const reconnectAttempts = useRef(0);
  const logoutFlag = useRef(false);

  useEffect(() => {
    const getProtocolAndPort = () => {
      const protocol = window.location.protocol; // 'http:' or 'https:'
      const websocketProtocol = protocol === 'https:' ? 'wss:' : 'ws:';
      const port = protocol === 'https:' ? 443 : 8000;
      return { protocol, websocketProtocol, port };
    };

    const { protocol, websocketProtocol, port } = getProtocolAndPort();
    const hostname = window.location.hostname;
    const apiVersion = "/api/v1";

    const API_LINK = `${protocol}//${hostname}:${port}${apiVersion}`;
    const WEBSOCKET_API_LINK = `${websocketProtocol}//${hostname}:${port}${apiVersion}`;

    setApiLink(API_LINK);
    setWebsocketApiLink(WEBSOCKET_API_LINK);

    console.log('API_LINK:', API_LINK);
    console.log('WEBSOCKET_API_LINK:', WEBSOCKET_API_LINK);
  }, []);

  const refreshTokenExpireMinutes = Number("30");

  useEffect(() => {
    const token = Cookies.get('access_token');
    if (token) {
      accessToken.current = token;
      setIsLoggedIn(true);
    }
  }, []);

  const handleLogout = useCallback(async () => {
    try {
      await axios.post(`${apiLink}/auth/logout`, {}, {
        headers: { Authorization: `Bearer ${accessToken.current}` },
      });
    } catch (error) {
      console.error('Logout error:', error);
      if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
        console.warn('Tokens might be expired.');
      }
    } finally {
      Cookies.remove('access_token');
      Cookies.remove('refresh_token');
      accessToken.current = '';
      setIsLoggedIn(false);
      logoutFlag.current = true;
      if (ws.current) {
        ws.current.close();
      }
    }
  }, [apiLink]);

  const connectWebSocket = useCallback(() => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      return;
    }

    console.log('Connecting to WebSocket...');
    const websocket_link = `${websocketApiLink}/websocket/chat`;
    if (websocketApiLink === '') {
      console.error('WebSocket API link is empty.');
      return;
    }
    ws.current = new WebSocket(websocket_link);

    ws.current.onopen = () => {
      console.log('WebSocket connection established.');
      setWsReady(true);
      reconnectAttempts.current = 0;
    };

    ws.current.onmessage = (event) => {
      const message = JSON.parse(event.data);
      const newMessage: Message = {
        id: uuidv4(),
        chat_id: message.chat_id,
        content: message.content,
        created_at: new Date().toISOString(),
        files: [],
        type: 'AI',
        langsmith_id: message.langsmith_id,
        updated_at: new Date().toISOString(),
      };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setNewMessageAdded(true);
    };

    ws.current.onclose = () => {
      console.log('WebSocket connection closed.');
      setWsReady(false);
      if (!logoutFlag.current) {
        attemptReconnect();
      }
    };

    ws.current.onerror = (error) => {
      console.error('WebSocket error:', error);
      setWsReady(false);
      if (!logoutFlag.current) {
        attemptReconnect();
      }
    };

    const attemptReconnect = () => {
      if (reconnectAttempts.current < 5) {
        const timeout = Math.min(1000 * 2 ** reconnectAttempts.current, 30000);
        reconnectAttempts.current += 1;
        setTimeout(() => {
          console.log(`Reconnecting... Attempt ${reconnectAttempts.current}`);
          connectWebSocket();
        }, timeout);
      } else {
        console.log('Max reconnection attempts reached.');
      }
    };
  }, [websocketApiLink]);

  useEffect(() => {
    if (isLoggedIn) {
      logoutFlag.current = false;
      connectWebSocket();
    } else {
      if (ws.current) {
        ws.current.close();
      }
    }

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [isLoggedIn, connectWebSocket]);

  const handleLogin = (token: string) => {
    accessToken.current = token;
    setIsLoggedIn(true);
  };

  useEffect(() => {
    const refreshToken = async () => {
      const refreshToken = Cookies.get('refresh_token');
      if (refreshToken && apiLink) {
        try {
          const response = await axios.post(`${apiLink}/auth/refresh?refresh_token=${refreshToken}`);
          if (response.status === 200 && response.data.access_token) {
            Cookies.set('access_token', response.data.access_token, { expires: 1 });
            Cookies.set('refresh_token', response.data.refresh_token, { expires: 7 });
            accessToken.current = response.data.access_token;
          }
        } catch (error) {
          console.error('Token refresh error:', error);
          handleLogout();
        }
      }
    };

    refreshToken();

    const interval = setInterval(() => {
      refreshToken();
    }, refreshTokenExpireMinutes * 1000 * 60);

    return () => clearInterval(interval);
  }, [apiLink, handleLogout, refreshTokenExpireMinutes]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      setCommitInfo('in development state right now');
    } else {
      fetch('/info.txt')
        .then(response => response.text())
        .then(data => setCommitInfo(data))
        .catch(error => console.error('Error fetching commit info:', error));
    }
  }, []);

  const handleMouseDown = (e: React.MouseEvent) => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e: MouseEvent) => {
    const sidebarMinWidth = 250; // Minimum width of the sidebar
    const maxMessageInfoWidth = window.innerWidth - sidebarMinWidth;
    const minMessageInfoWidth = 200; // Minimum width for MessageInfo

    let newWidth = window.innerWidth - e.clientX;
    if (newWidth > maxMessageInfoWidth) {
      newWidth = maxMessageInfoWidth;
    } else if (newWidth < minMessageInfoWidth) {
      newWidth = minMessageInfoWidth;
    }

    setMessageInfoWidth(`${newWidth}px`);
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  return (
    <Router>
      <main style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <AppBar position="static">
          <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6" component="div">
              Gart Tech
            </Typography>
            <nav>
              <ul style={{ display: 'flex', gap: '10px', listStyle: 'none', padding: 0, margin: 0 }}>
                <li>
                  <Button color="inherit" component={Link} to="/">Chat</Button>
                </li>
                <li>
                  <Button color="inherit" component={Link} to="/graph-explorer">Graph Explorer</Button>
                </li>
              </ul>
            </nav>
            {isLoggedIn &&
              <Button
                onClick={handleLogout}
                variant="contained"
                color="secondary"
                id="logout-button"
              >
                Logout
              </Button>}
          </Toolbar>
        </AppBar>
        <div style={{ display: 'flex', flex: 1, maxHeight: "calc(100vh - 142px)" }}>
          {!isLoggedIn ? <Login apiLink={apiLink} onLogin={handleLogin} /> :
            <>
              <Routes>
                <Route path="/" element={
                  <>
                    <Sidebar selectedChat={selectedChat} setSelectedChat={setSelectedChat} onSelectChat={setSelectedChat} accessToken={accessToken.current} apiLink={apiLink} />
                    <div style={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
                      {selectedChat ?
                        <Chat
                          chat={chats}
                          messages={messages}
                          setMessages={setMessages}
                          newMessageAdded={newMessageAdded}
                          setNewMessageAdded={setNewMessageAdded}
                          selectedChat={selectedChat}
                          accessToken={accessToken.current}
                          apiLink={apiLink}
                          ws={ws.current as WebSocket}
                          wsReady={wsReady}
                          onSelectMessage={setSelectedMessage}
                        />
                        :
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                          <Typography variant="h5" color="textSecondary">Select a chat to start messaging</Typography>
                        </div>
                      }
                      {selectedMessage && (
                        <div style={{ width: messageInfoWidth, backgroundColor: 'white', boxShadow: '-2px 0 5px rgba(0,0,0,0.1)', position: 'relative',  flexShrink: 0 }}>
                          <div style={{ maxHeight: 'calc(100vh - 140px)', overflowY: 'auto' }}>
                            <MessageInfo
                              apiLink={apiLink}
                              accessToken={accessToken.current}
                              message={selectedMessage}
                              onClose={() => setSelectedMessage(null)}
                            />
                            <div
                              style={{
                                width: '10px',
                                height: '100%',
                                backgroundColor: 'lightgray',
                                cursor: 'ew-resize',
                                position: 'absolute',
                                top: 0,
                                left: '-10px',
                              }}
                              onMouseDown={handleMouseDown}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                } />
                <Route path="/graph-explorer" element={<GraphExplorer apiLink={apiLink} accessToken={accessToken.current} />} />
              </Routes>
            </>
          }
        </div>
        <footer style={{ display: "flex", paddingTop: '28px', paddingLeft: '26px', backgroundColor: 'white', borderTop: '1px solid lightgray', gap: '30px' }}>
          <Typography variant="body2">© Gart Technology {new Date().getFullYear()}</Typography>
          <Typography variant="body2">Commit: {commitInfo}</Typography>
        </footer>
      </main>
    </Router>
  );
};

export default App;
