import React, { useState, useCallback, ChangeEvent, KeyboardEvent } from 'react';
import { TextField, InputAdornment, IconButton, Typography } from '@mui/material';
import { Send as SendIcon, AttachFile as AttachFileIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { v4 as uuidv4 } from 'uuid';

interface FileChunk {
    name: string;
    content: string;
    chunkIndex: number;
}

interface FileData {
    name: string;
    chunks: FileChunk[];
}

interface Message {
    type: string;
    content: string;
    bot_id: string;
    chat_id: string;
    files: FileData[];
}

interface MessageInputProps {
    selectedChat: {
        bot_id: string;
        chat_id: string;
    };
    ws: WebSocket;
    setMessages: React.Dispatch<React.SetStateAction<any[]>>;
    setNewMessageAdded: React.Dispatch<React.SetStateAction<boolean>>;
}

const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB per chunk

const readFileAsBase64 = (file: File, start: number, end: number): Promise<FileChunk> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        const blob = file.slice(start, end);
        reader.onload = () =>
            resolve({
                name: file.name,
                content: reader.result!.toString().split(',')[1], // Remove the data URL prefix
                chunkIndex: start / CHUNK_SIZE,
            });
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
};

const MessageInput: React.FC<MessageInputProps> = ({ selectedChat, ws, setMessages, setNewMessageAdded }) => {
    const [newMessage, setNewMessage] = useState('');
    const [files, setFiles] = useState<File[]>([]);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = Array.from(event.target.files!);
        const maxSize = 100 * 1024 * 1024; // 100MB in bytes
        const maxFiles = 3;

        const validFiles = selectedFiles.filter(file => file.size <= maxSize);

        if (validFiles.length + files.length > maxFiles) {
            alert(`You can only upload up to ${maxFiles} files.`);
            return;
        }

        if (validFiles.length !== selectedFiles.length) {
            alert('Some files exceed the 100MB size limit and were not added.');
        }

        setFiles([...files, ...validFiles]);
    };

    const handleRemoveFile = (index: number) => {
        setFiles(files.filter((_, i) => i !== index));
    };

    const handleSendMessage = useCallback(async () => {
        if (newMessage.trim() || files.length > 0) {
            if (selectedChat) {
                let base64Files: FileData[] = [];
                if (files.length > 0) {
                    for (const file of files) {
                        const chunks: FileChunk[] = [];
                        for (let start = 0; start < file.size; start += CHUNK_SIZE) {
                            const end = Math.min(start + CHUNK_SIZE, file.size);
                            const chunk = await readFileAsBase64(file, start, end);
                            chunks.push(chunk);
                        }
                        base64Files.push({ name: file.name, chunks });
                    }
                }

                const message: Message = {
                    type: 'message',
                    content: newMessage,
                    bot_id: selectedChat.bot_id,
                    chat_id: selectedChat.chat_id,
                    files: base64Files,
                };

                ws.send(JSON.stringify(message));

                const newMessages = {
                    _id: uuidv4(),
                    chat_id: selectedChat.chat_id,
                    content: newMessage,
                    created_at: new Date().toISOString(),
                    files: files.map((file) => ({ file_name: file.name })),
                    type: 'HUMAN',
                    langsmith_id: null,
                    updated_at: new Date().toISOString(),
                };

                setMessages((prevMessages) => [...prevMessages, newMessages]);

                setNewMessage('');
                setFiles([]);
                setNewMessageAdded(true);
            }
        }
    }, [files, newMessage, selectedChat, setMessages, ws, setNewMessageAdded]);

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                    variant="outlined"
                    fullWidth
                    id="message-input"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            handleSendMessage();
                        }
                    }}
                    placeholder="Type a message..."
                    multiline
                    minRows={1}
                    maxRows={8}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <input
                                    accept=".doc,.docx,.pdf,.txt,.xls,.xlsx,.ppt,.pptx,.jpg,.jpeg,.png,.gif,.mp4,.avi,.mp3,.wav,.zip,.rar,.7z"
                                    style={{ display: 'none' }}
                                    id="file-input"
                                    type="file"
                                    onChange={handleFileChange}
                                    data-testid="file-input"
                                    multiple
                                />
                                <label htmlFor="file-input">
                                    <IconButton color="primary" component="span" id="select-file-button" data-testid="select-file-button">
                                        <AttachFileIcon />
                                    </IconButton>
                                </label>
                                <IconButton color="primary" onClick={handleSendMessage} id="send-message-button" data-testid="send-message-button">
                                    <SendIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            {files.length > 0 && (
                <div style={{ marginTop: '10px', display: 'flex', gap: '20px' }}>
                    {files.map((file, index) => (
                        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                            <Typography variant="body2" style={{ marginRight: '10px' }}>
                                {file.name}
                            </Typography>
                            <button
                                onClick={() => handleRemoveFile(index)}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '6px',
                                    borderRadius: '5px',
                                    backgroundColor: '#f50057',
                                    color: '#fff',
                                    border: 'none',
                                    cursor: 'pointer',
                                }}
                                id="remove-file-button"
                                data-testid="remove-file-button"
                            >
                                <DeleteIcon />
                            </button>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default MessageInput;
