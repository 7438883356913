import React, { useState } from 'react';
import axios from 'axios';
import { Button, TextField, Box, Typography } from '@mui/material';

const GraphSidebar: React.FC<{ apiLink: string; accessToken: string }> = ({ apiLink, accessToken }) => {
    const [file, setFile] = useState<File | null>(null);
    const [botName, setBotName] = useState<string>('');

    const onDragStart = (event: React.DragEvent<HTMLDivElement>, nodeType: string) => {
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.effectAllowed = 'move';
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setFile(event.target.files[0]);
        }
    };

    const handleUpload = async () => {
        if (!file) {
            alert('Please select a file first.');
            return;
        }

        if (!botName) {
            alert('Please enter a bot name.');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('bot_name', botName);
        // Add other form data as needed
        // formData.append('telegram_token', 'YourTelegramToken');
        // formData.append('discord_token', 'YourDiscordToken');
        // formData.append('whatsapp_1msgio_token', 'YourWhatsAppToken');
        // formData.append('whatsapp_1msgio_channel_id', 'YourWhatsAppChannelId');
        console.log("apiLink", apiLink);
        console.log("accessToken", accessToken);
        try {
            const response = await axios.post(`${apiLink}/bot_config`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
            console.log('File uploaded successfully:', response.data);
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    return (
        <aside>
            <Typography variant="h6" gutterBottom>
                You can drag these nodes to the pane on the right.
            </Typography>
            <div className="dndnode input" onDragStart={(event) => onDragStart(event, 'input')} draggable>
                Input Node
            </div>
            <div className="dndnode" onDragStart={(event) => onDragStart(event, 'default')} draggable>
                Default Node
            </div>
            <div className="dndnode output" onDragStart={(event) => onDragStart(event, 'output')} draggable>
                Output Node
            </div>
            <Box mt={2}>
                <TextField
                    label="Bot Name"
                    variant="outlined"
                    fullWidth
                    value={botName}
                    onChange={(e) => setBotName(e.target.value)}
                    margin="normal"
                />
                <Button
                    variant="contained"
                    component="label"
                    fullWidth
                    sx={{ marginBottom: 2 }}
                >
                    Browse File
                    <input
                        type="file"
                        accept=".json"
                        hidden
                        onChange={handleFileChange}
                    />
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleUpload}
                >
                    Upload JSON
                </Button>
            </Box>
        </aside>
    );
};

export default GraphSidebar;
