import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { Box, IconButton, Typography, Accordion, AccordionSummary, AccordionDetails, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RefreshIcon from '@mui/icons-material/Refresh';

interface Run {
  name: string;
  run_type: string;
  start_time: string;
  end_time: string;
  status: string;
  prompt_tokens: number;
  completion_tokens: number;
  total_tokens: number;
  total_cost: number;
  inputs: any;
  outputs: any;
  child_runs?: Run[];
}

interface MessageInfoProps {
  apiLink: string;
  accessToken: string;
  message: {
    langsmith_id: string;
  };
  onClose: () => void;
}

const MessageInfo: React.FC<MessageInfoProps> = ({ apiLink, accessToken, message, onClose }) => {
  const [runData, setRunData] = useState<Run | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get<Run>(`${apiLink}/message/trace/${message.langsmith_id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
        timeout: 30000,
      });
      setRunData(response.data);
    } catch (error: any) {
      if (error.code === 'ECONNABORTED') {
        setError('Request timed out. Please try again.');
      } else {
        setError('Error fetching data. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  }, [apiLink, accessToken, message]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const renderRunDetails = (run: Run) => (
    <Accordion key={run.name} sx={{ width: '100%' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ wordBreak: 'break-word' }}>{run.name}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body2">
          <strong>Run Type:</strong> {run.run_type}<br />
          <strong>Start Time:</strong> {run.start_time}<br />
          <strong>End Time:</strong> {run.end_time}<br />
          <strong>Status:</strong> {run.status}<br />
          <strong>Prompt Tokens:</strong> {run.prompt_tokens}<br />
          <strong>Completion Tokens:</strong> {run.completion_tokens}<br />
          <strong>Total Tokens:</strong> {run.total_tokens}<br />
          <strong>Total Cost:</strong> {run.total_cost}<br />
          <strong>Inputs:</strong> <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{JSON.stringify(run.inputs, null, 2)}</pre><br />
          <strong>Outputs:</strong> <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{JSON.stringify(run.outputs, null, 2)}</pre><br />
        </Typography>
        {run.child_runs && run.child_runs.length > 0 && (
          <Box sx={{ marginLeft: 2 }}>
            {run.child_runs.map(childRun => renderRunDetails(childRun))}
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: 'calc(100vw - 250px)', // Ensure it doesn't exceed the available space
        padding: '20px',
        position: 'relative',
        overflow: 'auto', // Allow scrolling if content exceeds available space
        boxSizing: 'border-box', // Ensure padding is included in the width calculation
      }}
    >
      <IconButton
        sx={{ position: 'absolute', top: '10px', right: '10px' }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <Typography variant="h6" gutterBottom>
        Run Data
      </Typography>
      {loading ? (
        <Typography>Loading...</Typography>
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        runData && renderRunDetails(runData)
      )}
      {error && (
        <Button
          variant="contained"
          color="primary"
          startIcon={<RefreshIcon />}
          onClick={fetchData}
          sx={{ marginTop: 2 }}
        >
          Reload
        </Button>
      )}
    </Box>
  );
};

export default MessageInfo;
