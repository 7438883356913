import React, { useEffect, useState, useCallback } from 'react';
import { List, ListItem, ListItemText, Collapse, ListSubheader, Alert, IconButton, Typography } from '@mui/material';
import { ExpandLess, ExpandMore, Add, Delete } from '@material-ui/icons';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';

interface Chat {
  chat_id: string;
  bot_id: string;
  name?: string;
}

interface Bot {
  bot_id: string;
  name?: string;
}

interface SidebarProps {
  selectedChat: Chat | null;
  setSelectedChat: (chat: Chat | null) => void;
  onSelectChat: (chat: Chat) => void;
  accessToken: string;
  apiLink: string;
}

interface OpenBotsState {
  [key: string]: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ selectedChat, setSelectedChat, onSelectChat, accessToken, apiLink }) => {
  const [openBots, setOpenBots] = useState<OpenBotsState>({});
  const [bots, setBots] = useState<Bot[]>([]);
  const [chats, setChats] = useState<Chat[]>([]);
  const [error, setError] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (selectedChat) {
      setOpenBots(prevOpenBots => ({
        ...prevOpenBots,
        [selectedChat.bot_id]: true
      }));
    }
  }, [selectedChat, setOpenBots]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const chatId = params.get('chat_id');
    const botId = params.get('bot_id');
    if (chatId && botId) {
      onSelectChat({ chat_id: chatId, bot_id: botId });
    }
  }, [location.search, onSelectChat]);

  const fetchChats = useCallback(async (botId: string) => {
    if (!accessToken) return;

    try {
      const response = await axios.get(`${apiLink}/chat/my?bot_id=${botId}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      if (response.status === 200) {
        setChats(prevChats => [
          ...prevChats.filter(chat => chat.bot_id !== botId),
          ...(response.data.chats || [])
        ]);
      } else {
        setError('Failed to retrieve chats');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }, [apiLink, accessToken]);

  const selectChat = (chatId: string, botId: string) => {
    onSelectChat({ chat_id: chatId, bot_id: botId });
    navigate(`?chat_id=${chatId}&bot_id=${botId}`);
  };

  useEffect(() => {
    const multiplyBots = (bots: Bot[], multiplier: number) => {
      return Array.from({ length: multiplier }, () => bots).flat();
    };

    const fetchBots = async () => {
      if (!accessToken) return;

      try {
        const response = await axios.get(`${apiLink}/bot?bot_status=RUN`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });

        if (response.status === 200) {
          console.log('Bots:', response.data.bots);
          const botsData = multiplyBots(response.data.bots, 1);
          setBots(botsData);
          botsData.forEach(bot => fetchChats(bot.bot_id));
        } else {
          setError('Failed to retrieve bots');
        }
      } catch (error) {
        setError('Failed to retrieve bots');
        console.error('Error:', error);
      }
    };

    fetchBots();
  }, [apiLink, fetchChats, accessToken]);

  const handleBotClick = (botId: string) => {
    setOpenBots((prevOpenBots) => ({
      ...prevOpenBots,
      [botId]: !prevOpenBots[botId],
    }));
  };

  const addChatToBot = async (botId: string) => {
    if (!accessToken) return;

    try {
      const response = await axios.post(`${apiLink}/chat/start?bot_id=${botId}`, {}, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      if (response.status === 201) {
        const newChat = response.data;
        setChats((prevChats) => [...prevChats, newChat]);
        fetchChats(botId);
        selectChat(newChat.chat_id, botId);
        setOpenBots((prevOpenBots) => ({
          ...prevOpenBots,
          [botId]: true,
        }));
      } else {
        setError('Failed to create chat');
      }
    } catch (error) {
      setError('Failed to create chat');
      console.error('Error:', error);
    }
  };

  const deleteChat = async (chat_id: string, bot_id: string) => {
    try {
      await axios.delete(`${apiLink}/chat/${bot_id}/${chat_id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      setChats((prevChats) => prevChats.filter((chat) => chat.chat_id !== chat_id));
      navigate('');
    } catch (err) {
      console.error('Failed to delete chat:', err);
    }
  };

  const renderBotListItem = (bot: Bot) => (
    <ListItem
      button
      onClick={() => handleBotClick(bot.bot_id)}
      style={{ borderBottom: '1px solid #ddd' }}
      id={`bot-${bot.bot_id}`}
      data-testid="bot-item"
    >
      <ListItemText
        primary={
          bot.name ? (
            <Typography component="span" style={{ wordBreak: 'break-word' }}>
              Bot Name:<br />
              {bot.name}
            </Typography>
          ) : (
            `Bot ID: ${bot.bot_id}`
          )
        }
      />
      {openBots[bot.bot_id] ? <ExpandLess id={`collapse-bot-${bot.bot_id}`} data-testid="collapse-icon" /> : <ExpandMore id={`expand-bot-${bot.bot_id}`} data-testid="expand-icon" />}
      <IconButton onClick={() => addChatToBot(bot.bot_id)} size="small" id={`add-chat-bot-${bot.bot_id}`} data-testid="add-chat-button">
        <Add />
      </IconButton>
    </ListItem>
  );

  const renderChatListItem = (chat: Chat) => (
    <ListItem
      button
      key={chat.chat_id}
      onClick={() => selectChat(chat.chat_id, chat.bot_id)}
      style={{ backgroundColor: selectedChat && selectedChat.chat_id === chat.chat_id ? '#f0f0f0' : 'transparent', borderBottom: '1px solid #ddd' }}
      id={`chat-${chat.chat_id}`}
      data-testid="chat-item"
    >
      <ListItemText
        primary={
          chat.name ? (
            <Typography component="span" style={{ wordBreak: 'break-word' }}>
              Chat Name:<br />
              {chat.name}
            </Typography>
          ) : (
            `Chat ID: ${chat.chat_id}`
          )
        }
      />
      <IconButton onClick={() => deleteChat(chat.chat_id, chat.bot_id)} id={`delete-chat-${chat.chat_id}`} data-testid="delete-chat-button">
        <Delete style={{ color: '#ff8b8b', fontSize: '16px' }} />
      </IconButton>
    </ListItem>
  );

  const renderBotChats = (bot: Bot) => (
    <Collapse in={openBots[bot.bot_id]} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {chats
          .filter((chat) => chat.bot_id === bot.bot_id)
          .map((chat) => renderChatListItem(chat))}
      </List>
    </Collapse>
  );

  return (
    <div style={{ width: '250px', minWidth: '250px', borderRight: '1px solid #ddd', overflow: 'auto' }} id="bot-chat-container" data-testid="bot-chat-container">
      <List
        component="nav"
        subheader={
          <ListSubheader component="div" id="active-rooms-chats-header" data-testid="active-rooms-chats-header">
            Active Rooms and Chats
          </ListSubheader>
        }
      >
        {bots.map((bot) => (
          <div key={bot.bot_id} id={`bot-container-${bot.bot_id}`} data-testid="bot-container">
            {renderBotListItem(bot)}
            {renderBotChats(bot)}
          </div>
        ))}
      </List>
      {error && <Alert severity="error" id="error-alert" data-testid="error-alert">{error}</Alert>}
    </div>
  );
};

export default Sidebar;
